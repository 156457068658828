<template>
  <div class="wrap">
    <div
      v-if="title"
      class="title"
      :class="{
        'title-small': isTitleSmall
      }"
    >
      <texts
        :text="title"
        :size="isTitleSmall ? 'min' : 'small'"
        color="gray"
      />
    </div>
    <div class="input">
      <icons
        v-if="nullError"
        iconName="error"
        size="min"
        color="caution"
        class="input-icon-error"
      />
      <icons
        v-else-if="accept"
        iconName="check"
        size="min"
        color="emphasis"
        class="input-icon-accept"
      />
      <icons
        v-if="isPass"
        :iconName="showPass ? 'eyeClose' : 'eyeOpen'"
        size="min"
        isButton
        class="input-icon-pass"
        @icon-click="showPass = !showPass"
      />
      <input
        ref="inputTarget"
        :name="name"
        :placeholder="placeholder"
        :value="value"
        :type="isPass && !showPass ? 'password' : 'text'"
        class="input-inner input-inner-pass"
        :class="{
          'input-inner-error': nullError,
          'input-inner-multi': isPass && value.length !== 0
        }"
        @input="$emit('input', $event.target.value)"
        v-on:focusin="showPopup = true"
        v-on:focusout="
          isNameValidation ? (showPopup = true) : (showPopup = false)
        "
      >
      <popup-balloon-input
        v-if="popupView && !isNameValidation"
        :showPopup="showPopup"
        :error="nullError"
        :isBottom="isBottom"
        :class="{ 'input-popup-none-text': !popupText }"
      >
        <texts
          :text="popupText"
          size="min"
          :color="error ? 'caution' : 'default'"
          isShowAll
        />
      </popup-balloon-input>
    </div>
    <popup-balloon-name-suggest
      v-if="popupView && isNameValidation && error"
      :error="error"
      :showPopup="showPopup"
      :nameValidationSuggests="nameValidationSuggests"
      class="input-popup-name-validation"
      @select-suggest="selectSuggest"
    />
    <popup-balloon-email-suggest
      v-if="
        popupView &&
          emailSuggest &&
          nameValidationSuggests &&
          nameValidationSuggests.length > 0
      "
      :error="error"
      :showPopup="showPopup"
      :nameValidationSuggests="nameValidationSuggests"
      class="input-popup-name-validation"
      @select-suggest="selectSuggest"
    />
  </div>
</template>

<script>
import icons from '@/components/atoms/icon.vue'
import texts from '@/components/atoms/text.vue'
import popupBalloonInput from '@/components/atoms/popup-balloon-input.vue'
import popupBalloonNameSuggest from '@/components/molecules/popup-balloon-name-suggest.vue'
import popupBalloonEmailSuggest from '@/components/molecules/popup-balloon-email-suggest.vue'

export default {
  components: {
    icons,
    texts,
    popupBalloonInput,
    popupBalloonNameSuggest,

    popupBalloonEmailSuggest
  },
  data() {
    return {
      /** パスワードを表示中かどうか */
      showPass: false,
      /** ポップアップを表示中かどうか */
      showPopup: false,
      onFocus: false
    }
  },
  computed: {
    nullError() {
      /** valueがnullの時にerrorがtrueを返さないようにするcomputed **/
      if (this.value === '') {
        return false
      } else {
        return this.error
      }
    }
  },
  methods: {
    selectSuggest(suggest) {
      this.$emit('input', suggest)
    }
  },
  mounted() {
    if (this.focusStart) {
      this.$refs.inputTarget.focus()
    }
  },
  props: {
    /** プレースホルダー */
    placeholder: String,
    /** インプットの上に表示するテキスト */
    title: String,
    /** 親に戻す値 親側は:value.syncで受け取る */
    value: [String, Number],
    /** ポップアップを表示・非表示 */
    popupView: {
      type: Boolean,
      default: false
    },
    /** ポップアップに表示するテキスト */
    popupText: String,
    /** エラーが発生している場合 */
    error: {
      type: Boolean,
      default: false
    },
    /** バリデーションが通っている場合 */
    accept: {
      type: Boolean,
      default: false
    },
    /** パスワードの入力かどうか */
    isPass: {
      type: Boolean,
      default: false
    },
    /** 名前の入力欄かどうか */
    isNameValidation: {
      type: Boolean,
      default: false
    },
    /** emailのサジェスト（@gmail.comなど）を表示するかどうか */
    emailSuggest: {
      type: Boolean,
      default: false
    },
    /** 名前入力欄のエラー時のサジェスト */
    nameValidationSuggests: Array,
    /** inputタグに付与するname */
    name: String,
    /** focusされた状態で入るかどうか */
    focusStart: Boolean,
    /** バルーンを下向き矢印で表示するかどうか */
    isBottom: Boolean,
    /** タイトルを最小表示するかどうか */
    isTitleSmall: Boolean
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin: 0 0 $space-base;
  &-small {
    margin-bottom: $space-text;
  }
}

.wrap {
  position: relative;
}

.input {
  position: relative;

  &-icon {
    &-pass {
      position: absolute;
      top: 50%;
      right: $space-small;
      transform: translateY(-50%);
    }
    &-error {
      @extend .input-icon-pass;
      + .input-icon-pass {
        right: $space-small * 3;
      }
    }
    &-accept {
      @extend .input-icon-pass;
      + .input-icon-pass {
        right: $space-small * 3;
      }
    }
  }
  &-inner {
    display: block;
    width: 100%;
    height: 100%;
    min-height: adjustVH(48);
    padding: $space-sub;
    background: $background-sub;
    font-size: $text-base;
    border-radius: adjustVW(8);
    transition: box-shadow $transition-base;
    &:focus {
      background: $background;
      box-shadow: $border-radius-emphasis inset, $box-shadow-hover;
    }
    &-error {
      background: $background;
      box-shadow: $border-radius-caution inset;
      &:focus {
        box-shadow: $border-radius-caution inset, $box-shadow-hover;
      }
    }
    &-pass {
      padding: $space-sub $space-small * 3 $space-sub $space-sub;
    }
    &-multi {
      padding: $space-sub $space-small * 5 $space-sub $space-sub;
    }
    &::placeholder {
      color: $border-gray;
    }
  }
  &-popup {
    &-none-text {
      display: none;
    }
  }
}
</style>
